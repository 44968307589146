body,html {
  margin: 0;
  padding: 0;
  font-family: roboto;
  font-size: calc( 1080 / 1920 * 1.65vw );
  background-color: black;
}

body {
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
}
@media screen and (width: 1920px) and (height: 1080px) and (orientation: landscape) {
  body {
    height: calc( 1080 / 1920 * 100vw );
  }
}