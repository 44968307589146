.Gallery {
    &-container {
        position: relative;
    }
    &-image {
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 2s;
        z-index: 0;
        &.active {
            z-index: 10;
            opacity: 1;
        }
        img {
            margin: 0 auto;
            height: calc( 1080 / 1920 * 100vw );
            display: block;
        }
    }
}
