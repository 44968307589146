@import url('https://fonts.googleapis.com/css?family=Roboto');
.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: black;
  color: #f0f0f0;
  @media screen and (width: 1920px) and (height: 1080px) and (orientation: landscape) {
    height: calc( 1080 / 1920 * 100vw );
  }
}

.App-offline-marker {
  display: none;
  border-radius: 50px;
  position: fixed;
  top: 1vw;
  right: 1vw;
  height: 3vw;
  width: 3vw;
  background-color: red;
  z-index: 1000;
  &.active {
    display: block;
  }
}

.App-logo {
  height: calc( 1080 / 1920 * 16vw );
  position: absolute;
  top: 0;
  right: calc( 1080 / 1920 * 10vw );
}
