@import "config";

@mixin daily-special($scale: 1) {
  $scale_height: 1080 / 1920 * $scale;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  border-left: #{0.2 * $scale}vw solid $color_brand;
  font-size: 1rem * $scale;

  &.past:not(.highlighted) {
    opacity: 0.5;
  }

  &.highlighted {
    border-left: none;

    .DailySpecial-text {
      color: white;
      background-color: rgb(47, 47, 47);

      .DailySpecial-annotation {
        margin-top: #{.5 * $scale_height}vw;
        white-space: pre-wrap;
      }

      .DailySpecial-caption {
        font-size: 2.2em;
      }

      .DailySpecial-price {
        color: $color_brand;
      }
    }
  }

  .DailySpecial-body {
    color: rgb(47, 47, 47);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }

  .DailySpecial-text {
    background-color: rgb(236, 236, 237);
    display: flex;
    padding: #{.5 * $scale_height}vw #{.55 * $scale}vw;
    align-content: flex-start;
    flex-direction: column;
    font-family: Roboto;
    box-sizing: border-box;
    height: #{9 * $scale}vw;
    overflow: hidden;
    width: 100%;

    &>* {
      flex-wrap: wrap;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }

  .DailySpecial-price {
    position: absolute;
    bottom: #{0.2 * $scale_height}vw;
    right: #{.6 * $scale_height}vw;
    font-size: 2em;
  }

  .DailySpecial-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .DailySpecial-date, .DailySpecial-dow {
    font-size: 2em;
    color: $color_brand;
    text-transform: uppercase;
    margin-left: #{0.25 * $scale}vw;
    transform: translateY(-#{.506 * $scale}vw);
  }
  .DailySpecial-date {
    font-size: 1.5em;
    padding-right: 0.5em;
  }

  .DailySpecial-ingredients {
    font-size: .3em;
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: #{0.7 * $scale_height}vw;
    left: #{17 * $scale_height}vw;
  }

  .DailySpecial-caption {
    font-size: 2em;
  }

  .DailySpecial-photo-container {
    width: #{9 * $scale}vw;
    height: #{9 * $scale}vw;
    overflow: hidden;
  }

  .DailySpecial-photo img {
    object-fit: cover;
    object-position: 50% 50%;
    min-height: #{9 * $scale}vw;
    width: #{9 * $scale}vw;
  }
}

.DailySpecialMain-specials .DailySpecial {
  @include daily-special(1);
}

.DailySpecialMain-special-today .DailySpecial {
  @include daily-special((100 - 22 - 26) / 16);
}
