.MenuMain-container{
    padding: 0;
    margin: 0;
    list-style: none;
    height: calc( 1080 / 1920 * 100vw );
    max-width: 100vw;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.MenuMain-block {
    width: 33.333vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.MenuMain-block > * {
    width: 33.333vw;
    flex: 0 1 calc( 1080 / 1920 * 10vw );
    box-sizing: border-box;

    height: calc( 1080 / 1920 * 10vw );
    max-height: calc( 1080 / 1920 * 10vw );
    overflow: hidden;
}

.MenuMain-category {
    display: flex;
    align-items: center;
    font-size: calc( 1080 / 1920 * 4vw );
    padding: calc( 1080 / 1920 * 1vw ) 0 calc( 1080 / 1920 * 1vw ) calc( 1080 / 1920 * 2vw );
    border-bottom:  calc( 1080 / 1920 * 1vw ) solid black;
    border-right:  calc( 1080 / 1920 * 1vw ) solid black;
    /* color: rgb(255,109,0); */
    background-color: rgb(236,236,237, 0.15);
    text-transform: uppercase;
}

.MenuMain-item {
    padding: calc( 1080 / 1920 * 0.5vw ) 0 0.5h 0;
}

.MenuMain-item-body {
    overflow: hidden;
    /* background-color: rgb(236,236,237, 0.3); */
    padding: 0 calc( 1080 / 1920 * 1vw ) 0 0;
    color: #f0f0f0;
    display: flex;
    align-items: stretch;
}


.MenuMain-item-body > * {
    flex: 1;
}

.MenuMain-item-left{
    flex-grow: 99;
}

.MenuMain-item-right{
    text-align: right;
    flex-shrink: 99;
    white-space: nowrap;
}

.MenuMain-image {
    width: calc( 1080 / 1920 * 9vw );
    height: calc( 1080 / 1920 * 9vw );
    flex: 0 0 calc( 1080 / 1920 * 9vw );
    overflow: hidden;
    margin-right: 0.25vw;
}
.MenuMain-image img {
    height: calc( 1080 / 1920 * 9vw );
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.MenuMain-price {
    font-size: calc( 1080 / 1920 * 2.8vw );
    text-align: right;
}

.MenuMain-caption{
    font-size: calc( 1080 / 1920 * 2.8vw );
    max-width: 22vw;
    color: rgb(255,109,0);
}

.MenuMain-annotation {
    font-size: calc( 1080 / 1920 * 2vw );
    white-space: pre-wrap;
}

.MenuMain-ingredients {
    font-size: calc( 1080 / 1920 * 1vw );
}
.MenuMain-ingredients > * {
    text-align: right;
}
