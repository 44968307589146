@import url('https://fonts.googleapis.com/css?family=Roboto');
@import "config";

$today_top: calc( 1080 / 1920 * 22vw );

.DailySpecialMain-title {
  text-transform: uppercase;
  font-size: 6.2vw;
  color: $color_brand;
  position: absolute;
  z-index: 1000;
  top: 0;
  height: $today_top;
  display: flex;
  align-items: center;
  left: calc( 1080 / 1920 * 10vw );
}

.DailySpecialMain-special-today {
  position: absolute;
  top: $today_top;
  left: 0;
  right: 0;
  bottom: calc( 1080 / 1920 * 26vw );
}

.DailySpecialMain-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.DailySpecialMain-specials {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.DailySpecialMain-legal {
  position: absolute;
  right: 20vw;
  top: 0;
  display: flex;
  >div {
    @media screen and (max-width: 1500px) {
      padding-top: 1rem;
    }
    padding-top: .5rem;
    padding-right: 1rem;
    >a {
      text-transform: uppercase;
      color: #555;
      text-decoration: none;
      font-size: 14px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
      @media screen and (max-width: 370px) {
        font-size: 10.5px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &:first-child {
      padding-right: .5rem;
    }
    &:last-child >a:before {
      content: "|";
      display: inline-block;
      color: inherit;
      margin-right: .5rem;
    }
  }
}

// RESPONSIVE BREAKPOINTS

@mixin daily-special-responsive($size, $text-big, $text) {
    .DailySpecial {
      .DailySpecial-dow {
        font-size: $text-big;
      }
      .DailySpecial-photo-container {  
        width: $size;
        height: $size;
      }
      .DailySpecial-photo img {
        min-height: $size;
        width: $size;
      }
      .DailySpecial-text {
        height: $size;
        >* {
          font-size: $text;
        }
      }
      .DailySpecial-caption {
        font-size: $text-big;
      }
      .DailySpecial-price {
        font-size: $text-big;
      }
    }
}

@mixin daily-special-legal-responsive {
  position: absolute;
  right: auto;
  left: 50%;
  top: auto;
  bottom: 0;
  transform: translateX(-50%);
  >div {
    padding-bottom: 10px;
    padding-top: 0;
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-right: 1.5rem;
    }
    &:last-child >a:before {
      margin-right: 1.5rem;
    }
  }
}


// orientation portrait if smaller 16:9 with aspect-ratio

@media (max-aspect-ratio: 3/4){ 
  @media screen and (max-width:1100px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      bottom: auto;
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(17vw, 3rem, 2rem);
      flex-direction: column;
      bottom: 35px;
      .DailySpecial {
        .DailySpecial-header {
          margin-top: .8rem;
          padding-left: .3em;
        }
      }
    }
    .DailySpecialMain-legal {
      @include daily-special-legal-responsive();
    }
  }
  @media screen and (max-width:500px) and (orientation:portrait) {
    .DailySpecialMain-title {
      font-size: 5.2vw;
    }
    .DailySpecialMain-special-today {
      top: calc( 1080 / 1920 * 18vw);
      @include daily-special-responsive(22vw, 16px, 12.5px);
      .DailySpecial {
        .DailySpecial-ingredients {
          display: none;
        }
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(18vw, 15px, 11px);
      .DailySpecial .DailySpecial-dow {
        transform: none;
      }
    }
  }
}

@media (max-aspect-ratio: 2/3){ 
  @media screen and (max-width: 800px) and (orientation:portrait) {
    .App-logo {
      right: calc( 1080 / 1920 * 5vw);
    }
    .DailySpecialMain-title {
      left: calc( 1080 / 1920 * 5vw);
    }
    .DailySpecialMain-special-today {
      @include daily-special-responsive(32vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        font-size: 11.5px;
        left: calc(32vw + 1rem);
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(18vw, 5rem, 3rem);
    }
  }
  @media screen and (max-width:200px) and (orientation:portrait) {
    .DailySpecialMain-title {
      font-size: 5.2vw;
    }
    .DailySpecialMain-special-today {
      top: calc( 1080 / 1920 * 18vw);
      @include daily-special-responsive(26vw, 13px, 10px);
      .DailySpecial {
        .DailySpecial-ingredients {
          display: none;
        }
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(20.5vw, 12px, 9px);
      .DailySpecial .DailySpecial-dow {
        transform: none;
      }
    }
  }
}

@media (max-aspect-ratio: 10/16){ 
  @media screen and (max-width: 800px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(32vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        left: calc(32vw + 1rem);
        font-size: 11.5px;
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(21vw, 5rem, 3rem);
    }
  }
  @media screen and (max-width: 420px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(35vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        left: calc(35vw + 1rem);
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(24vw, 5rem, 3rem)
    }
  }
}

@media (max-aspect-ratio: 20/37){
  @media screen and (max-width:800px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(40vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        left: calc(40vw + 1rem);
        font-size: 12px;
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(25vw, 5rem, 3rem);
    }
  }
}

@media (max-aspect-ratio: 9/18){
  @media screen and (max-width: 800px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(43vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        left: calc(43vw + 1rem);
        font-size: 11.5px;
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(28vw, 5rem, 3rem);
    }
  }
}

@media (max-aspect-ratio: 9/19) {
  @media screen and (max-width: 800px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(45vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        left: calc(45vw + 1rem);
        font-size: 10.5px;
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(30vw, 5rem, 3rem);
    }
  }
}

@media (max-aspect-ratio: 4/9) {
  @media screen and (max-width:1400px) and (orientation:portrait) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(48vw, 2em, 3.25rem);
      .DailySpecial .DailySpecial-ingredients {
        left: calc(48vw + 1rem);
        font-size: 9.5px;
      }
    }
    .DailySpecialMain-specials {
      @include daily-special-responsive(32vw, 5rem, 3rem);
    }
  }
}

// orientation landscape if smaller 16:9 with aspect-ratio

@media screen and (max-width: 830px) and (orientation: landscape) {
  .DailySpecialMain-special-today .DailySpecial .DailySpecial-ingredients {
    font-size: 1.4rem;
  }
  .DailySpecialMain-specials .DailySpecial .DailySpecial-text .DailySpecial-annotation {
    font-size: 1.4rem;
  }
}

@media (min-aspect-ratio: 37/20){
  .App-logo{
    height: calc( 1080 / 1920 * 24vh);
    right: calc( 1080 / 1920 * 15vh);
  } 
  .DailySpecialMain-title {
    height: calc( 1080 / 1920 * 30vh);
    left: calc( 1080 / 1920 * 15vh);
  }
  .DailySpecialMain-special-today {
    top: calc( 1080 / 1920 * 30vh);
    bottom: auto;
  }
}
@media (min-aspect-ratio: 18/9){
  .App-logo{
    height: calc( 1080 / 1920 * 20vh);
  } 
  .DailySpecialMain-title {
    height: calc( 1080 / 1920 * 24vh);
    font-size: 8vh;
  }
  .DailySpecialMain-special-today {
    top: calc( 1080 / 1920 * 24vh);
  }
}

@media (min-aspect-ratio: 19/9) {
  .DailySpecialMain-special-today .DailySpecial {
    .DailySpecial-photo-container {  
      width: 26vw;
      height: 26vw;
    }
    .DailySpecial-photo img {
      width: 26vw;
      min-height: 26vw;
    }
    &.highlighted .DailySpecial-text {
      height: 26vw;
    }
    .DailySpecial-ingredients {
      left: calc(26vw + 20px);
    }
  } 
}
@media (min-aspect-ratio: 11/5) {
  .DailySpecialMain-special-today {
    @include daily-special-responsive(51vh, 10vh, 6vh);
    .DailySpecial {
      .DailySpecial-ingredients {
        left: calc(51vh + 20px);
        font-size: .3em;
      }
      &.highlighted .DailySpecial-text {
        height: 51vh;
        .DailySpecial-caption {
          font-size: 10vh;
        }
      }
    }
  } 
  .DailySpecialMain-specials {
      @include daily-special-responsive(27vh, 2rem, 1.1rem);
      .DailySpecial {
        .DailySpecial-dow {
          transform: none;
          font-size: 15px;
        }
      }
  }
}


// aspect-ratio 1:1 

@media (aspect-ratio: 1/1) {
  .App-logo{
    height: calc( 1080 / 1920 * 16vw);
    right: calc( 1080 / 1920 * 5vw);
  } 
  .DailySpecialMain-title {
    height: calc( 1080 / 1920 * 17vw);
    font-size: 5.2vw;
    left: calc( 1080 / 1920 * 5vw);
  }

  .DailySpecialMain-special-today {
    top: calc( 1080 / 1920 * 17vw);
    @include daily-special-responsive(22vw, 1.5em, 1em);
    .DailySpecial {
      &.highlighted .DailySpecial-text .DailySpecial-caption {
        font-size: 1.5em;
      }
      .DailySpecial-ingredients {
        left: calc(22vw + 2rem);
        font-size: .3em;
      }
    }
  }  

  .DailySpecialMain-specials {
    @include daily-special-responsive(15vw, 3rem, 2rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    .DailySpecial {
      margin-top: .5rem;
      &:first-of-type .DailySpecial-header {
        margin-top: 0;
      }
      .DailySpecial-header {
        position: absolute;
        left: calc(15vw + .8rem);
        top: .8rem;
        margin: 0;
        border: 0;
        z-index: 10;
      }
      .DailySpecial-dow {
        margin: 0;
        transform: none;
      }
      .DailySpecial-photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #2f2f2f;
        z-index: 0;
      }
      .DailySpecial-text {
        height: 30vh;
        .DailySpecial-caption {
          padding-top: calc(15vw + 0.8rem);
        }
        .DailySpecial-price {
          bottom: auto;
          bottom: calc(15vw + 0.8rem);
          right: .8rem;
          color: #fff;
        }
      }
    } 
  }
  @media screen and (max-width: 450px) and (max-height: 450px) and (min-width: 250px) and (min-height: 250px) {
    .DailySpecialMain-special-today {
      @include daily-special-responsive(20vw, 16px, 12.5px);
      .DailySpecial {
        .DailySpecial-ingredients {
          display: none;
        }
      }
    }  
  
    .DailySpecialMain-specials {
      @include daily-special-responsive(14vw, 15px, 11.5px);
      .DailySpecial {
        .DailySpecial-header {
          left: 14.5vw;
          top: 0;
        }
        .DailySpecial-text {
          height: 34vw;
          .DailySpecial-caption {
            padding-top: 14.5vw;
          }
          .DailySpecial-price {
            bottom: auto;
            top: 8.5vw;
            right:3px;
          }
        }
      } 
    }
  }
}
